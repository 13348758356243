<template>
    <div class="error-page">
        <div class="container">
            <div class="title">
                {{ $i18n.get(`Page not found.`) }}
                <br>
                {{ $i18n.get(`Very suspicious...`) }}</div>
            <div class="description">
                {{ $i18n.get(`Crime scene is taped off. Please, proceed to the Main page for the investigation period`) }}</div>
            <w-button type="orange" :size=" $store.state.isMobile ? 'middle' : 'large'" :to="{ name: 'home' }">
                {{ $i18n.get(`Go to Homepage`) }}
            </w-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'error'
}
</script>

<style lang="less">
    .error-page {
        background-color: var(--dark-blue);
        background-image: url(./images/error-page-bg.webp);
        background-size: 2560px 1112px;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;

        > .container {
            color: var(--white);
            width: var(--main-width);
            margin: 0px auto;
            transform: translateY(80px);
            * {
                max-width: 390px;
            }
            > .title {
                font-weight: 800;
                font-size: 32px;
                line-height: 44px;
            }

            > .description {
                margin-top: 10px;
                font-weight: normal;
                font-size: 16px;
                line-height: 26px;
            }

            > .btn {
                margin-top: 30px;
                width: 235px;
                white-space: nowrap;
            }
        }
    }

    @media (max-width: 1023px) {
        .error-page {
            background-position: calc(50% + 100px) center;
        }
    }

    @media (max-width: 768px) {
        .error-page {
            background-size: 1589px 690px;
            background-position: calc(50% + 150px) center;
            min-height: 465px;

            > .container {
                width: 100%;
                padding: 15px;
                text-align: center;
                transform: translateY(60px);

                * {
                    max-width: inherit;
                }

                > .title {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 32px;
                }

                > .description {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                    max-width: 272px;
                    margin: 0 auto;
                }

                > .btn {
                    margin: 10px auto;
                }
            }
        }
    }
</style>

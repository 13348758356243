<template>
    <div class="terms-container">
        <div class="terms-content">
            <w-breadcrumb :items="breadcrumb"></w-breadcrumb>
<!--            <vue-showdown :markdown="text.default"></vue-showdown>-->
          <div v-html="text.default"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'terms',
    props: {
        page: {
            type: String
        },
        breadcrumb: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            text: {
                default: ''
            }
        }
    },
    async created() {
        this.text = await import('./snippets/' + this.page)
    }
}
</script>

<style lang="less">
.terms-container {
    background-color: var(--white);

    .terms-content {
        max-width: var(--main-width-full);
        margin: 25px auto 50px;
        color: var(--dark-blue);
        
        td, th {
          font-size: 14px;
          line-height: 129%;
        }

        tr {
          &:nth-child(2n) {
            background: var(--main-grey);
          }
        }
        

        td, th {
          text-align: left;
          padding: 6px 6px 6px 25px;
        }

        table {
            border-radius: 15px;
            overflow: hidden;
            border-collapse: collapse;
            width: 100%;
            margin-bottom: 35px;

            thead {
              background: var(--dark-blue-20);
              th {
                font-weight: 600;
                font-size: 14px;
                line-height: 129%;
              }
            }
        }

        h1 {
          width: var(--main-width-full);
          margin: 50px auto 20px;
          font-weight: 700;
          font-size: 46px;
          line-height: 69px;
        }

        h2 {
            font-weight: bold;
            font-size: 22px;
            line-height: 26px;
            margin: 8px 0px;
        }

        h3 {
          font-weight: 700;
          font-size: 28px;
          line-height: 129%;
          margin-bottom: 16px;
        }

        ol, ul {
            margin-left: 30px;
            
            li, p {
                margin: 20px 0px;
                font-weight: normal;
                font-size: 16px;
                line-height: 29px;
                padding-left: 30px;

                &::marker {
                    padding: 10px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 26px;
                }

                p {
                    padding-left: 0px;
                }
            }
        }

        p {
            line-height: 29px;
          font-weight: 400;
          font-size: 16px;
          width: var(--main-width-full);
          margin: 0 auto 30px;
        }

        .margin {height: 80px;}
    }
}

@media (max-width: 1023px) {
    .terms-container {
        .terms-content {
            h1 {
                margin: 40px auto 15px;
                font-weight: 700;
                font-size: 36px;
                line-height: 54px;
            }

            h2 {
                font-weight: bold;
                font-size: 22px;
                line-height: 26px;
            }

            h3 {
                margin: 34px 0px 14px;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
            }

            ol, ul {
                li, p {
                    font-size: 14px;
                    line-height: 25px;
                    margin: 5px 0px;

                    &::marker {
                        padding: 10px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }

            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}

@media (max-width: 767px) {
    .terms-container {
        .terms-content {
            max-width: 100%;
            padding: 0px 30px 50px 30px;
            margin-top: 0px;

            > .links {
                margin: 0px -30px;
                width: calc(100% + 60px);
            }

            table {
                margin-left: 0px;
                font-size: 12px;
                line-height: 20px;
                margin: 0px -15px;
                width: calc(100% + 30px);
            }

            td, th {
                padding: 2px;
            }

            h1 {
                margin: 20px auto 10px;
                padding: 0 15px;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
            }

            h2 {
                font-weight: bold;
                font-size: 22px;
                line-height: 26px;
            }

            h3 {
                margin: 15px 0px 5px;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
            }

            ol, ul {
                padding: 15px;
                margin-left: 0px;

                li, p {
                    padding-left: 15px;
                    font-size: 12px;
                    line-height: 20px;
                    
                    &::marker {
                        padding: 10px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 26px;
                    }

                    &:first-child {
                        margin-top: 0px;
                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }

            p {
                font-size: 12px;
                line-height: 20px;
            }

            .margin { height: 30px; }
        }
    }
}
</style>
